var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', [_vm._v(" " + _vm._s(_vm.$t("promotionAg.".concat(_vm.label))) + " ")])];
      },
      proxy: true
    }])
  }, [_c('v-select', {
    staticClass: "v-select-wrap",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "id": _vm.label,
      "multiple": "",
      "label": "agencyName",
      "close-on-select": false,
      "reduce": function reduce(val) {
        return {
          agencyName: val.agencyName,
          agencyCode: val.agencyCode
        };
      },
      "filterable": false,
      "loading": _vm.loading,
      "placeholder": _vm.$t("".concat(_vm.placeholder)),
      "options": _vm.agenciesManagerList
    },
    on: {
      "open": _vm.openAgenciesManager,
      "search": _vm.searchAgenciesManager,
      "search:blur": _vm.handleUpdateSelect
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex-center justify-content-between"
        }, [_c('span', {
          staticClass: "d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(data.agencyName) + " ")]), _c('span', {
          staticClass: "d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(data.agencyCode) + " ")])])];
      }
    }, {
      key: "spinner",
      fn: function fn(data) {
        return [data.loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.inputData,
      callback: function callback($$v) {
        _vm.inputData = $$v;
      },
      expression: "inputData"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }